@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");
@font-face {
  font-family: 'LinLibertine';
  src: url("../fonts/LinLibertine_R.ttf"); }

.banner {
  position: relative; }
  .banner__slide {
    position: relative; }
  .banner__background {
    height: 100vh !important;
    width: 100%;
    background-size: cover !important;
    background-position: left bottom !important; }
    @media (max-width: 1299px) {
      .banner__background {
        height: 800px !important; } }
    @media (max-width: 767px) {
      .banner__background {
        height: 390px !important; } }
  .banner__container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%; }
    @media (max-width: 767px) {
      .banner__container {
        position: static;
        padding: 0 !important; } }
  .banner__content {
    text-align: center;
    max-width: 770px;
    margin-right: 10%;
    margin-top: 6%; }
    @media (max-width: 1734px) {
      .banner__content {
        max-width: 570px; } }
    @media (max-width: 767px) {
      .banner__content {
        position: static;
        margin: 0;
        padding: 30px 20px;
        padding-bottom: 70px;
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        background: #f7fcfd; } }
    .banner__content p {
      color: #000000;
      font-size: 70px;
      font-family: 'LinLibertine';
      font-weight: 400;
      margin: 0;
      line-height: 100%;
      margin-bottom: 40px; }
      @media (max-width: 1734px) {
        .banner__content p {
          font-size: 50px;
          margin-bottom: 30px; } }
      @media (max-width: 1299px) {
        .banner__content p {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 126px;
          margin: auto;
          max-width: 572px;
          background: rgba(247, 252, 253, 0.85);
          padding-top: 5px;
          padding-bottom: 15px;
          line-height: 100%; } }
      @media (max-width: 767px) {
        .banner__content p {
          position: static;
          margin: 0 auto;
          margin-bottom: 20px;
          padding: 0;
          font-size: 40px;
          background: #f7fcfd; } }
  .banner__scroll {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 52px;
    margin: auto;
    z-index: 1;
    width: 72px;
    height: 72px;
    background: url("../images/scrollDown.png") no-repeat;
    transition: background 0.3s; }
    @media (max-width: 1299px) {
      .banner__scroll {
        display: none; } }
    .banner__scroll:hover {
      background: url("../images/scrollDown-yellow.png") no-repeat; }
  @media (max-width: 1299px) {
    .banner__button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 41px;
      margin: auto; } }
  @media (max-width: 767px) {
    .banner__button {
      position: static; } }

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0);
  padding-bottom: 37px;
  transition: background 0.3s ease-in-out; }
  @media (max-width: 1734px) {
    .header {
      padding-bottom: 25px; } }
  .header.sticky {
    background: #000000;
    position: relative;
    top: 0; }
  .header.scroll {
    background: #000000; }
  @media (max-width: 1299px) {
    .header {
      background: #000000;
      padding-bottom: 0;
      position: absolute; } }
  @media (max-width: 767px) {
    .header {
      padding: 0;
      height: 105px; } }
  .header__container {
    position: relative; }
    @media (max-width: 1299px) {
      .header__container {
        max-width: 100% !important; } }
  .header__row {
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 1299px) {
      .header__row {
        flex-direction: column-reverse;
        justify-content: center;
        overflow-x: hidden; } }
  .header__logo {
    position: absolute;
    top: 0;
    left: 15px; }
    .header__logo img {
      display: block; }
      @media (max-width: 1734px) {
        .header__logo img {
          max-width: 165px; } }
      @media (max-width: 1299px) {
        .header__logo img {
          max-width: 140px; } }
      @media (max-width: 767px) {
        .header__logo img {
          max-width: 105px; } }
    @media (max-width: 1299px) {
      .header__logo {
        position: static;
        display: flex;
        justify-content: center; } }
  .header__col {
    width: calc(100% - 225px); }
    @media (max-width: 1299px) {
      .header__col {
        width: 100%; } }
  .header__contact {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 45px;
    padding-top: 39px; }
    @media (max-width: 1734px) {
      .header__contact {
        padding-top: 25px;
        padding-bottom: 32px; } }
    @media (max-width: 1299px) {
      .header__contact {
        padding-top: 23px;
        padding-bottom: 18px;
        margin: 0 -18px;
        display: flex;
        justify-content: center; } }
    @media (max-width: 767px) {
      .header__contact {
        display: none; } }
  .header__text {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-left: 37px; }
    @media (max-width: 1299px) {
      .header__text {
        padding: 0 18px; } }
    .header__text img {
      display: inline-block;
      margin-right: 10px; }
    .header__text a {
      color: #FFFFFF;
      text-decoration: none;
      transition: color 0.3s; }
      .header__text a:hover {
        color: #FFAD00; }
    .header__text--bold a {
      font-weight: 900; }

@media (max-width: 1299px) {
  .mainMenu {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    background: #000000;
    display: none;
    padding-top: 135px;
    padding-bottom: 60px;
    overflow-y: auto; } }

.mainMenu.scroll ul > li ul > li > a, .mainMenu.sticky ul > li ul > li > a {
  color: #000000; }

.mainMenu.scroll ul > li > a, .mainMenu.sticky ul > li > a {
  color: #FFFFFF; }
  .mainMenu.scroll ul > li > a::after, .mainMenu.sticky ul > li > a::after {
    border-right-color: #FFFFFF !important;
    border-bottom-color: #FFFFFF !important; }

.mainMenu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end; }
  @media (max-width: 1299px) {
    .mainMenu ul {
      flex-direction: column;
      justify-content: center; } }
  .mainMenu ul li {
    margin-left: 55px;
    position: relative; }
    @media (min-width: 1300px) {
      .mainMenu ul li:first-child {
        display: none; } }
    @media (max-width: 1734px) {
      .mainMenu ul li {
        margin-left: 30px; } }
    @media (max-width: 1299px) {
      .mainMenu ul li {
        text-align: center;
        margin-bottom: 20px;
        margin-left: 0; } }
    .mainMenu ul li.current-menu-item > a, .mainMenu ul li.current-menu-parent > a {
      color: #FFAD00 !important; }
      .mainMenu ul li.current-menu-item > a::after, .mainMenu ul li.current-menu-parent > a::after {
        border-right-color: #FFAD00 !important;
        border-bottom-color: #FFAD00 !important; }
    .mainMenu ul li.menu-item-has-children > a {
      display: inline-block;
      padding-right: 25px;
      position: relative; }
      .mainMenu ul li.menu-item-has-children > a::after {
        display: block;
        content: '';
        width: 9px;
        height: 9px;
        border: 2px solid rgba(0, 0, 0, 0);
        border-right-color: #000000;
        border-bottom-color: #000000;
        transform: rotate(45deg);
        border-radius: 2px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 9px;
        margin: auto;
        transition: border-color 0.3s; }
    @media (min-width: 1300px) {
      .mainMenu ul li:hover > a {
        color: #FFAD00; }
        .mainMenu ul li:hover > a::after {
          border-right-color: #FFAD00 !important;
          border-bottom-color: #FFAD00 !important; }
      .mainMenu ul li:hover > ul {
        opacity: 1;
        visibility: visible; } }
    @media (max-width: 1299px) {
      .mainMenu ul li.open > a {
        color: #FFAD00; }
        .mainMenu ul li.open > a::after {
          border-right-color: #FFAD00 !important;
          border-bottom-color: #FFAD00 !important;
          transform: rotate(-135deg);
          bottom: -11px; } }
    .mainMenu ul li a {
      font-size: 18px;
      color: #000000;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      text-decoration: none;
      transition: color 0.3s; }
      @media (max-width: 1734px) {
        .mainMenu ul li a {
          font-size: 16px; } }
      @media (max-width: 1299px) {
        .mainMenu ul li a {
          color: #FFFFFF;
          font-size: 22px; }
          .mainMenu ul li a::after {
            border-right-color: #FFFFFF !important;
            border-bottom-color: #FFFFFF !important;
            width: 10px !important;
            height: 10px !important;
            border-width: 3px !important; } }
    .mainMenu ul li ul {
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 236px;
      padding-top: 40px;
      left: -10px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s; }
      @media (max-width: 1734px) {
        .mainMenu ul li ul {
          padding-top: 30px; } }
      @media (max-width: 1299px) {
        .mainMenu ul li ul {
          position: static;
          opacity: 1;
          visibility: visible;
          width: 100%;
          background: #D0D4D5;
          padding-top: 0px;
          margin-top: 10px;
          display: none; } }
      .mainMenu ul li ul li {
        margin: 0;
        margin-bottom: 4px; }
        .mainMenu ul li ul li:first-child {
          display: block !important; }
        .mainMenu ul li ul li.current-menu-item > a {
          background: #000000; }
        .mainMenu ul li ul li:last-child {
          margin-bottom: 0; }
        @media (max-width: 1299px) {
          .mainMenu ul li ul li {
            margin-bottom: 0; } }
        @media (min-width: 1300px) {
          .mainMenu ul li ul li:hover > a {
            color: #FFFFFF;
            background: #000000; } }
        .mainMenu ul li ul li a {
          text-transform: none;
          display: block;
          background: rgba(247, 252, 253, 0.7);
          color: #000000;
          transition: color 0.3s, background 0.3s;
          padding: 7px 10px;
          box-sizing: border-box; }
          @media (max-width: 1299px) {
            .mainMenu ul li ul li a {
              background: none;
              display: block;
              padding: 15px 10px; } }

.mainMenu__button {
  display: none;
  flex-direction: column;
  width: 49px;
  height: 31px;
  position: absolute;
  top: 112px;
  right: 25px;
  z-index: 99;
  transition: top 0.3s; }
  @media (max-width: 1299px) {
    .mainMenu__button {
      display: flex; } }
  @media (max-width: 1299px) and (max-width: 767px) {
    .mainMenu__button {
      top: 37px; } }
  @media (max-width: 1299px) {
      .mainMenu__button.fixed {
        position: fixed;
        top: 39px;
        display: none; }
      .mainMenu__button.active {
        top: 39px; }
        .mainMenu__button.active span:nth-child(1) {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          transform: rotate(45deg); }
        .mainMenu__button.active span:nth-child(2) {
          opacity: 0;
          visibility: hidden; }
        .mainMenu__button.active span:nth-child(3) {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          transform: rotate(-45deg); }
      .mainMenu__button span {
        display: block;
        width: 100%;
        height: 5px;
        margin-bottom: 8px;
        background: #FFFFFF;
        transition: transform 0.3s, opacity 0.3s, visibility 0.3s; }
        .mainMenu__button span:last-child {
          margin-bottom: 0; } }

.button, .banner__button, .section__button, .offer__button, .bannerSection__button, .pageBlock__button, .component__button, .component__officeButton {
  color: #FFFFFF;
  font-size: 22px;
  font-weight: 900;
  text-align: center;
  display: block;
  max-width: 300px;
  width: 100%;
  text-transform: uppercase;
  padding: 23px 10px;
  box-sizing: border-box;
  text-decoration: none;
  background-size: 200% auto;
  transition: 0.5s;
  box-shadow: 15px 15px 17px 0 rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(to right, #AE73FF 0%, #258EE6 51%, #AE73FF 100%); }
  @media (max-width: 1734px) {
    .button, .banner__button, .section__button, .offer__button, .bannerSection__button, .pageBlock__button, .component__button, .component__officeButton {
      max-width: 242px;
      font-size: 18px;
      padding: 19px 10px; } }
  .button:hover, .banner__button:hover, .section__button:hover, .offer__button:hover, .bannerSection__button:hover, .pageBlock__button:hover, .component__button:hover, .component__officeButton:hover {
    background-position: right center; }
  .button--red, .section__button, .offer__col:nth-child(3n+1) .offer__button, .component__button--red, .component__officeButton--red {
    background-image: linear-gradient(to right, #E03B56 0%, #AE73FF 51%, #E03B56 100%); }
  .button--yellow, .offer__col:nth-child(3n-1) .offer__button, .pageBlock__button, .component__button--yellow, .component__officeButton--yellow {
    background-image: linear-gradient(to right, #FFAD00 0%, #E03B56 51%, #FFAD00 100%); }
  .button--center, .banner__button {
    margin: 0 auto; }

.section {
  background: #fff;
  padding: 34px 0;
  padding-bottom: 90px; }
  @media (max-width: 1299px) {
    .section {
      padding: 10px 0;
      padding-bottom: 60px; } }
  .section h2 {
    font-family: 'LinLibertine';
    font-size: 50px;
    color: #000000;
    line-height: 120%;
    text-align: center;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400;
    margin-bottom: 70px; }
    @media (max-width: 1734px) {
      .section h2 {
        font-size: 40px;
        max-width: 1000px; } }
    @media (max-width: 1299px) {
      .section h2 {
        max-width: 675px;
        margin-bottom: 35px; } }
    .section h2.left {
      text-align: left; }
    .section h2.smallMargin {
      margin-bottom: 20px; }
      @media (max-width: 1299px) {
        .section h2.smallMargin {
          margin-bottom: 32px; } }
      @media (max-width: 767px) {
        .section h2.smallMargin {
          margin-bottom: 0; } }
  .section--dark {
    background: #F7FCFD; }
  .section--noPaddingBottom {
    padding-bottom: 0; }
    @media (max-width: 1734px) {
      .section--noPaddingBottom {
        padding-bottom: 20px; } }
  @media (max-width: 1299px) {
    .section--mobileNoPaddingBottom {
      padding-bottom: 0; } }
  .section--large {
    padding: 139px 0; }
    @media (max-width: 1734px) {
      .section--large {
        padding: 100px 0; } }
    @media (max-width: 1299px) {
      .section--large {
        padding: 85px 0;
        padding-bottom: 185px; } }
    @media (max-width: 767px) {
      .section--large {
        padding: 20px 0;
        padding-bottom: 80px; } }
  .section__container--small {
    max-width: 1600px !important; }
  .section__container--xSmall {
    max-width: 1380px !important; }
    @media (max-width: 1734px) {
      .section__container--xSmall {
        max-width: 1100px !important; } }
  .section__row {
    display: flex;
    margin: 0 -15px; }
    @media (max-width: 1299px) {
      .section__row {
        flex-wrap: wrap; } }
    @media (max-width: 767px) {
      .section__row {
        flex-direction: column; } }
    .section__row--center {
      justify-content: center; }
    .section__row--alignCenter {
      align-items: center; }
      @media (max-width: 1299px) {
        .section__row--alignCenter {
          flex-direction: column; } }
    .section__row--larger {
      margin: 0 -4%; }
      @media (max-width: 1299px) {
        .section__row--larger {
          margin: 0 -15px; } }
    @media (max-width: 1299px) {
      .section__row--partners {
        padding-top: 44px; } }
  .section__col {
    padding: 0 15px;
    box-sizing: border-box; }
    @media (max-width: 1299px) {
      .section__col {
        width: 50%; } }
    @media (max-width: 767px) {
      .section__col {
        width: 100%; } }
    .section__col--large {
      padding: 0 4%; }
      @media (max-width: 1299px) {
        .section__col--large {
          padding: 0 15px;
          padding-bottom: 80px; } }
    .section__col--flexCenter {
      display: flex;
      align-items: center;
      justify-content: center; }
    .section__col--2 {
      width: 50%; }
      @media (max-width: 1299px) {
        .section__col--2 {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; } }
    @media (max-width: 1299px) {
      .section__col--coupon {
        max-width: 340px;
        margin: 0 auto;
        padding-bottom: 134px; } }
    @media (max-width: 767px) {
      .section__col--coupon {
        padding-bottom: 70px;
        max-width: 350px; } }
  .section__text {
    font-size: 22px;
    color: #737B7D;
    line-height: 140%; }
    .section__text--smallWidth {
      max-width: 324px; }
  .section__button {
    max-width: 391px;
    display: block;
    margin-top: 70px; }
    @media (max-width: 1734px) {
      .section__button {
        max-width: 320px; } }
    @media (max-width: 1299px) {
      .section__button {
        max-width: 314px;
        margin-top: 38px; } }

@media (max-width: 767px) {
  .whyUs {
    max-width: 400px;
    margin: auto;
    padding-top: 26px; } }

.whyUs__row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -15px; }
  @media (max-width: 1299px) {
    .whyUs__row {
      flex-direction: column; } }

.whyUs__col {
  padding: 0 15px;
  width: 20%;
  box-sizing: border-box; }
  @media (max-width: 1299px) {
    .whyUs__col {
      width: 100%;
      padding: 0 50px;
      padding-bottom: 70px; }
      .whyUs__col:last-child {
        padding-bottom: 0; } }
  @media (max-width: 1299px) and (max-width: 767px) {
    .whyUs__col {
      padding: 0 15px;
      padding-bottom: 50px; }
      .whyUs__col:last-child {
        padding-bottom: 0px; } }
  .whyUs__col:nth-child(4n-1) p::before {
    background: #258EE6; }
  .whyUs__col:nth-child(4n) p::before {
    background: #E03B56; }
  .whyUs__col:nth-child(4n+1) p::before {
    background: #FFAD00; }

.whyUs__block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 270px;
  margin: 0 auto; }
  @media (max-width: 1299px) {
    .whyUs__block {
      flex-direction: row;
      max-width: 100%; } }
  .whyUs__block img {
    display: block;
    margin: 0 auto; }
    @media (max-width: 1299px) {
      .whyUs__block img {
        max-width: 108px;
        width: 100%; } }
  .whyUs__block p {
    color: #737B7D;
    font-size: 22px;
    font-weight: bold;
    line-height: 120%;
    position: relative;
    padding-top: 33px; }
    @media (max-width: 1299px) {
      .whyUs__block p {
        margin: 0;
        padding: 0;
        text-align: left;
        margin-left: 27px;
        padding-left: 36px;
        width: calc(100% - 108px); } }
  @media (max-width: 1299px) and (max-width: 767px) {
    .whyUs__block p {
      margin-left: 10px;
      padding-left: 20px; } }
    .whyUs__block p::before {
      content: '';
      display: block;
      width: 74px;
      height: 3px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: #AE73FF; }
      @media (max-width: 1299px) {
        .whyUs__block p::before {
          width: 3px;
          height: 74px;
          top: 0;
          bottom: 0;
          left: 0;
          right: auto; } }

.offer {
  padding-top: 30px;
  margin: 0 auto; }
  @media (max-width: 1734px) {
    .offer {
      max-width: 1100px; } }
  @media (max-width: 1299px) {
    .offer {
      padding-top: 35px; } }
  @media (max-width: 767px) {
    .offer {
      margin: 0 -15px;
      padding-top: 26px; } }
  .offer__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    justify-content: center; }
    @media (max-width: 1299px) {
      .offer__row {
        margin: 0 -5px; } }
    @media (max-width: 767px) {
      .offer__row {
        flex-direction: column;
        margin: 0 -15px; } }
  .offer__col {
    width: 50%;
    padding: 0 15px;
    box-sizing: border-box;
    padding-bottom: 180px; }
    @media (max-width: 1734px) {
      .offer__col {
        padding-bottom: 110px; } }
    @media (max-width: 1299px) {
      .offer__col {
        padding: 0 15px;
        padding-bottom: 117px; } }
    @media (max-width: 767px) {
      .offer__col {
        width: 100%; } }
  .offer__block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    position: relative; }
    @media (max-width: 1734px) {
      .offer__block {
        max-width: 460px;
        margin: 0 auto; } }
    .offer__block img {
      display: block;
      transition: opacity 0.3s; }
    .offer__block:hover .offer__button {
      background-position: right center; }
    .offer__block:hover img {
      opacity: 0.9; }
  .offer__button {
    max-width: 558px;
    position: absolute;
    bottom: -12%;
    margin: auto;
    font-size: 30px;
    padding: 39px 10px; }
    @media (max-width: 1734px) {
      .offer__button {
        font-size: 24px;
        max-width: 415px;
        padding: 25px 10px;
        bottom: -9%; } }
    @media (max-width: 1299px) {
      .offer__button {
        font-size: 18px;
        max-width: 307px;
        bottom: -12%;
        padding: 20px 10px; } }

.bannerSection {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  min-height: 974px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  @media (max-width: 1734px) {
    .bannerSection {
      min-height: 812px; } }
  @media (max-width: 1024px) {
    .bannerSection {
      background-attachment: initial !important; } }
  @media (max-width: 767px) {
    .bannerSection {
      height: auto;
      min-height: 395px; } }
  .bannerSection__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .bannerSection__container {
        padding: 30px !important; } }
  .bannerSection h2 {
    font-family: 'LinLibertine';
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    font-size: 70px;
    max-width: 945px;
    margin: 0 auto;
    line-height: 120%; }
    @media (max-width: 1734px) {
      .bannerSection h2 {
        font-size: 50px;
        max-width: 750px; } }
    @media (max-width: 767px) {
      .bannerSection h2 {
        font-size: 40px; } }
  .bannerSection__button {
    box-shadow: none;
    position: absolute;
    bottom: 95px;
    left: 0;
    right: 0;
    margin: auto; }
    @media (max-width: 767px) {
      .bannerSection__button {
        position: static;
        margin-top: 32px; } }

.footer {
  background: #000000;
  padding: 117px 0;
  padding-bottom: 30px; }
  @media (max-width: 1734px) {
    .footer {
      padding: 90px 0;
      padding-bottom: 30px; } }
  @media (max-width: 1299px) {
    .footer {
      padding: 75px 0;
      padding-bottom: 30px; } }
  @media (max-width: 1299px) {
    .footer__container {
      padding: 0 30px !important; } }
  @media (max-width: 767px) {
    .footer__container {
      padding: 0 15px !important; } }
  .footer__row {
    display: flex;
    margin: 0 -15px; }
    @media (max-width: 1299px) {
      .footer__row {
        flex-direction: column; } }
    @media (max-width: 1299px) {
      .footer__row--mobileRow {
        flex-wrap: wrap;
        flex-direction: row; } }
    @media (max-width: 767px) {
      .footer__row--mobileRow {
        flex-direction: column; } }
  .footer__col {
    width: 25%;
    padding: 0 15px;
    box-sizing: border-box; }
    @media (max-width: 1299px) {
      .footer__col {
        width: 100%; } }
    .footer__col:last-child {
      width: 75%; }
      @media (max-width: 1299px) {
        .footer__col:last-child {
          padding-top: 87px;
          width: 100%; } }
  .footer__blocks {
    display: flex;
    margin: 0 -15px; }
    @media (max-width: 1299px) {
      .footer__blocks {
        flex-direction: column; } }
  .footer__list--top {
    padding: 0 15px;
    padding-bottom: 60px;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .footer__list--top {
        padding-bottom: 100px; } }
    .footer__list--top:first-child {
      width: 33.33%; }
      @media (max-width: 1299px) {
        .footer__list--top:first-child {
          width: 100%;
          padding-bottom: 110px; } }
      @media (max-width: 767px) {
        .footer__list--top:first-child {
          padding-bottom: 100px; } }
    .footer__list--top:last-child {
      width: 66.67%; }
      @media (max-width: 1299px) {
        .footer__list--top:last-child {
          width: 100%; } }
      .footer__list--top:last-child .footer__menu {
        column-count: 2; }
        @media (max-width: 767px) {
          .footer__list--top:last-child .footer__menu {
            column-count: 1; } }
  .footer__menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .footer__menu ul li {
      margin-bottom: 15px; }
      @media (max-width: 767px) {
        .footer__menu ul li {
          text-align: center; } }
      .footer__menu ul li:last-child {
        margin-bottom: 0; }
      .footer__menu ul li a {
        color: #D0D4D5;
        font-size: 20px;
        text-decoration: none;
        font-weight: 400;
        transition: color 0.3s; }
        .footer__menu ul li a:hover {
          color: #FFAD00; }
  .footer__header {
    font-size: 50px;
    color: #FFFFFF;
    line-height: 120%;
    font-family: 'LinLibertine';
    font-weight: 400;
    margin-top: -8px;
    border-bottom: 1px solid #737B7D;
    padding-bottom: 7px;
    margin-bottom: 12px; }
    @media (max-width: 767px) {
      .footer__header {
        text-align: center; } }
    .footer__header--small {
      font-size: 20px;
      font-family: 'Lato';
      font-weight: 900;
      color: #D0D4D5;
      border: 0;
      margin-top: 5px;
      margin-bottom: 18px; }
      .footer__header--small span {
        font-weight: 400; }
  .footer__contact {
    padding: 0 15px;
    box-sizing: border-box;
    width: 33.33%; }
    @media (max-width: 1299px) {
      .footer__contact {
        width: 50%;
        padding-bottom: 75px; }
        .footer__contact:last-child {
          padding-bottom: 0; } }
    @media (max-width: 767px) {
      .footer__contact {
        width: 100%; } }
  @media (max-width: 767px) {
    .footer__contactContent {
      margin: 0 auto;
      max-width: 255px; } }
  .footer__text {
    display: block;
    color: #D0D4D5;
    font-size: 20px;
    margin-bottom: 15px;
    position: relative; }
    .footer__text--address {
      padding-left: 31px; }
      .footer__text--address::before {
        content: '';
        display: block;
        width: 21px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        margin: auto;
        background: url("../images/home.png") no-repeat;
        background-repeat: no-repeat; }
    .footer__text--time {
      padding-left: 31px; }
      .footer__text--time::before {
        content: '';
        display: block;
        width: 21px;
        height: 21px;
        position: absolute;
        left: 0;
        top: 2px;
        margin: auto;
        background: url("../images/time.png") no-repeat;
        background-repeat: no-repeat; }
    .footer__text--phone a {
      position: relative;
      padding-left: 31px; }
      .footer__text--phone a::before {
        content: '';
        display: block;
        width: 21px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 4px;
        margin: auto;
        background: url("../images/phone-g.png") no-repeat;
        background-repeat: no-repeat;
        transition: background 0.3s; }
      .footer__text--phone a:hover::before {
        background: url("../images/phone-y.png") no-repeat; }
    .footer__text--mail a {
      position: relative;
      padding-left: 31px; }
      .footer__text--mail a::before {
        content: '';
        display: block;
        width: 21px;
        height: 14px;
        position: absolute;
        left: 0;
        top: 6px;
        margin: auto;
        background: url("../images/message-g.png") no-repeat;
        background-repeat: no-repeat;
        transition: background 0.3s; }
      .footer__text--mail a:hover::before {
        background: url("../images/message-y.png") no-repeat; }
    .footer__text a {
      color: #D0D4D5;
      transition: color 0.3s;
      text-decoration: none; }
      .footer__text a:hover {
        color: #FFAD00; }
    .footer__text:last-child {
      margin-bottom: 0; }
  @media (max-width: 1299px) {
    .footer__logo {
      display: block;
      margin: 0 auto; } }
  .footer__realization {
    margin-top: 100px;
    border-top: 1px solid #737B7D;
    padding: 36px 0;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px; }
    @media (max-width: 1299px) {
      .footer__realization {
        margin-top: 76px; } }
    @media (max-width: 767px) {
      .footer__realization {
        margin-top: 65px; } }
  @media (max-width: 767px) {
    .footer__links {
      display: flex;
      flex-direction: column; } }
  .footer__links a {
    color: #FFFFFF;
    text-decoration: none;
    transition: color 0.3s;
    margin: 0 35px;
    font-size: 18px; }
    .footer__links a:hover {
      color: #FFAD00; }
    @media (max-width: 767px) {
      .footer__links a {
        margin-bottom: 30px;
        text-align: center; } }
  .footer__copyright {
    margin-top: 29px; }
    @media (max-width: 767px) {
      .footer__copyright {
        margin-top: 20px;
        text-align: center; }
        .footer__copyright span {
          display: block; } }
    .footer__copyright img {
      margin-left: 10px;
      display: inline-block; }
      @media (max-width: 767px) {
        .footer__copyright img {
          display: block;
          margin: 0 auto;
          margin-top: 15px; } }
  .footer__divider {
    display: inline-block;
    margin: 0 10px; }
    @media (max-width: 767px) {
      .footer__divider {
        display: none !important; } }

.page {
  background: #FFFFFF;
  padding-bottom: 30px; }
  .page__banner {
    height: 575px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important; }
    @media (max-width: 1734px) {
      .page__banner {
        height: 479px; } }
    @media (max-width: 767px) {
      .page__banner {
        height: 232px; } }
    .page__banner--empty {
      height: 50px; }
      @media (max-width: 1299px) {
        .page__banner--empty {
          height: 0; } }
  .page__container {
    max-width: 1265px;
    box-sizing: border-box;
    padding: 0 25px;
    margin: 0 auto;
    padding-top: 27px; }
    @media (max-width: 767px) {
      .page__container {
        padding-left: 15px;
        padding-right: 15px; } }
  .page__title {
    color: #000000;
    font-size: 70px;
    font-family: 'LinLibertine';
    font-weight: 400;
    line-height: 120%; }
    @media (max-width: 1734px) {
      .page__title {
        font-size: 50px; } }
    @media (max-width: 767px) {
      .page__title {
        font-size: 40px;
        text-align: center; } }
  .page h1 {
    color: #000000;
    font-size: 35px;
    font-family: 'LinLibertine';
    font-weight: 400;
    line-height: 120%;
    margin: 14px 0;
    margin-bottom: 77px;
    max-width: 835px; }
    @media (max-width: 1734px) {
      .page h1 {
        font-size: 30px; } }
  .page__content {
    max-width: 984px;
    width: 100%;
    float: right; }

.pageBlock {
  background: #F7FCFD;
  padding: 140px 0;
  overflow: hidden; }
  @media (max-width: 1299px) {
    .pageBlock {
      padding: 80px 0; } }
  .pageBlock__container {
    max-width: 1400px; }
  .pageBlock__row {
    display: flex;
    margin: 0 -50px; }
    @media (max-width: 1299px) {
      .pageBlock__row {
        flex-direction: column-reverse; } }
    @media (max-width: 1024px) {
      .pageBlock__row {
        margin: 0 -15px; } }
    .pageBlock__row--center {
      justify-content: center; }
    .pageBlock__row--small {
      margin: 0 auto; }
  .pageBlock__col {
    padding: 0 50px;
    box-sizing: border-box;
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; }
    @media (max-width: 1024px) {
      .pageBlock__col {
        padding: 0 15px; } }
    .pageBlock__col:first-child {
      justify-content: center; }
    .pageBlock__col--left {
      align-items: inherit; }
      @media (max-width: 1299px) {
        .pageBlock__col--left {
          align-items: center; } }
    .pageBlock__col--center {
      justify-content: center;
      padding-bottom: 100px; }
      @media (max-width: 1299px) {
        .pageBlock__col--center {
          align-items: center;
          padding-bottom: 80px !important; } }
    @media (max-width: 1299px) {
      .pageBlock__col {
        width: 100%;
        justify-content: center;
        text-align: center;
        padding-bottom: 40px; } }
    .pageBlock__col--noPadding {
      padding-bottom: 0; }
    .pageBlock__col img {
      display: block;
      box-shadow: 50px 50px 50px 0 rgba(0, 0, 0, 0.18); }
    .pageBlock__col p {
      font-family: 'LinLibertine';
      color: #000000;
      font-size: 50px;
      font-weight: 400;
      max-width: 570px; }
      @media (max-width: 1299px) {
        .pageBlock__col p {
          font-size: 40px; } }
  .pageBlock--facebook {
    background: #FFFFFF;
    padding: 0;
    padding-top: 100px; }
    @media (max-width: 1024px) {
      .pageBlock--facebook {
        padding-top: 80px; } }
    .pageBlock--facebook img {
      box-shadow: none; }
  .pageBlock__button {
    max-width: 218px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 10px; }
    .pageBlock__button img {
      display: block;
      margin-right: 15px; }

.component {
  padding-top: 15px;
  padding-bottom: 15px; }
  .component:first-child {
    padding-top: 0; }
  .component--block {
    padding-bottom: 40px; }
  .component--contactContainer {
    padding-bottom: 100px; }
  .component--contact {
    padding: 80px 0;
    border-bottom: 1px solid #000000; }
    .component--contact:last-child {
      border-bottom: 0;
      padding-bottom: 20px; }
  .component h2 {
    margin: 0;
    font-weight: 700;
    color: #000000;
    font-size: 22px;
    line-height: 140%; }
  .component__text {
    color: #737B7D;
    line-height: 160%;
    font-size: 20px; }
    .component__text--large {
      color: #000000;
      font-size: 30px;
      line-height: 120%;
      padding: 20px 0;
      text-transform: uppercase; }
    .component__text p {
      line-height: 160%;
      margin-top: 0; }
    .component__text strong, .component__text b {
      font-weight: 900; }
    .component__text--highlight {
      background: #F7FCFD;
      padding: 20px 25px;
      margin: 0 -25px;
      box-sizing: border-box;
      color: #000000; }
      .component__text--highlight strong, .component__text--highlight b {
        font-size: 22px; }
      .component__text--highlight ul li::before {
        background: #000000 !important; }
  .component ul {
    list-style-type: none;
    padding: 0;
    padding-left: 5px;
    padding-bottom: 5px; }
    .component ul li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 10px; }
      .component ul li::before {
        content: '';
        display: block;
        width: 13px;
        height: 13px;
        background: #737B7D;
        position: absolute;
        top: 10px;
        left: 0;
        border-radius: 100%; }
  .component__contact {
    display: flex;
    margin: 0 -12px; }
    @media (max-width: 1024px) {
      .component__contact {
        flex-direction: column; } }
  .component__contactCol {
    padding: 0 12px;
    width: 50%;
    box-sizing: border-box; }
    @media (max-width: 1024px) {
      .component__contactCol {
        width: 100%; } }
    .component__contactCol img {
      display: block; }
  .component__contactBlock {
    background: #F7FCFD;
    padding: 0px 40px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline; }
    @media (max-width: 1024px) {
      .component__contactBlock {
        margin-top: 24px;
        padding: 30px 40px; } }
  .component__contactHeader {
    color: #000000;
    font-size: 22px;
    font-weight: 700;
    line-height: 160%; }
  .component__contactDesc {
    line-height: 120%;
    font-weight: 400;
    font-size: 20px;
    color: #000000; }
  .component__contactContent {
    padding-top: 15px; }
    @media (max-width: 767px) {
      .component__contactContent {
        margin: 0 auto;
        max-width: 255px; } }
  .component__contactText {
    display: block;
    color: #737B7D;
    font-size: 18px;
    margin-bottom: 15px;
    position: relative; }
    .component__contactText--address {
      padding-left: 31px; }
      .component__contactText--address::before {
        content: '';
        display: block;
        width: 21px;
        height: 20px;
        position: absolute;
        left: 0;
        top: 0;
        margin: auto;
        background: url("../images/home-dark.png") no-repeat;
        background-repeat: no-repeat; }
    .component__contactText--time {
      padding-left: 31px; }
      .component__contactText--time::before {
        content: '';
        display: block;
        width: 21px;
        height: 21px;
        position: absolute;
        left: 0;
        top: 2px;
        margin: auto;
        background: url("../images/time-dark.png") no-repeat;
        background-repeat: no-repeat; }
    .component__contactText--phone a {
      position: relative;
      padding-left: 31px; }
      .component__contactText--phone a::before {
        content: '';
        display: block;
        width: 21px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 4px;
        margin: auto;
        background: url("../images/phone-dark.png") no-repeat;
        background-repeat: no-repeat;
        transition: background 0.3s; }
      .component__contactText--phone a:hover::before {
        background: url("../images/phone-y.png") no-repeat; }
    .component__contactText--mail a {
      position: relative;
      padding-left: 31px; }
      .component__contactText--mail a::before {
        content: '';
        display: block;
        width: 21px;
        height: 14px;
        position: absolute;
        left: 0;
        top: 6px;
        margin: auto;
        background: url("../images/message-dark.png") no-repeat;
        background-repeat: no-repeat;
        transition: background 0.3s; }
      .component__contactText--mail a:hover::before {
        background: url("../images/message-y.png") no-repeat; }
    .component__contactText a {
      color: #737B7D;
      transition: color 0.3s;
      text-decoration: none; }
      .component__contactText a:hover {
        color: #FFAD00; }
    .component__contactText:last-child {
      margin-bottom: 0; }
  .component__contactMap {
    padding-top: 24px; }
    .component__contactMap iframe {
      width: 100% !important;
      height: 396px !important; }
  .component__button {
    margin-top: 24px;
    max-width: 503px;
    display: flex;
    align-items: center;
    padding: 17px 10px;
    justify-content: center; }
    @media (max-width: 1024px) {
      .component__button--map {
        font-size: 18px;
        max-width: 421px;
        margin: 0 auto;
        margin-top: 65px;
        padding: 10px; } }
    @media (max-width: 767px) {
      .component__button--map {
        text-align: left;
        padding-left: 30px; } }
    .component__button img {
      display: inline-block;
      margin-right: 18px;
      position: relative;
      top: -2px; }
  .component__background {
    padding: 40px 25px;
    margin: 0 -25px;
    background: #F7FCFD; }
    .component__background img {
      display: block;
      margin-bottom: 15px; }
  .component__blockHeader {
    font-size: 35px;
    color: #000000;
    line-height: 120%;
    font-family: 'LinLibertine';
    margin-bottom: 15px; }
  .component__blockText {
    line-height: 160%;
    font-size: 20px;
    color: #000000; }
  .component__icon {
    display: flex;
    max-width: 675px;
    padding-bottom: 85px;
    align-items: center; }
    @media (max-width: 767px) {
      .component__icon {
        padding-bottom: 40px; } }
    .component__icon:last-child {
      padding-bottom: 0; }
    .component__icon:nth-child(4n-1) .component__iconContent::before {
      background: #258EE6; }
    .component__icon:nth-child(4n) .component__iconContent::before {
      background: #E03B56; }
    .component__icon:nth-child(4n+1) .component__iconContent::before {
      background: #FFAD00; }
    .component__iconImage {
      max-width: 145px;
      padding-right: 30px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 767px) {
        .component__iconImage {
          width: 100%;
          max-width: 108px;
          padding-right: 20px; } }
      .component__iconImage img {
        display: block; }
    .component__iconContent {
      width: calc(100% - 145px);
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      box-sizing: border-box;
      position: relative; }
      @media (max-width: 767px) {
        .component__iconContent {
          width: calc(100% - 108px);
          padding-left: 20px; } }
      .component__iconContent::before {
        content: "";
        display: block;
        width: 3px;
        height: 74px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: #AE73FF; }
      .component__iconContent span {
        color: #737B7D; }
    .component__iconHeader {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 120%; }
      @media (max-width: 767px) {
        .component__iconHeader {
          font-size: 20px; } }
    .component__iconText {
      font-size: 20px;
      line-height: 120%; }
      @media (max-width: 767px) {
        .component__iconText {
          font-size: 18px; } }
  .component__office {
    padding: 25px 25px;
    background: #F7FCFD;
    margin: 0px -25px;
    margin-top: 20px;
    padding-bottom: 70px; }
    .component__office img {
      display: block; }
    .component__officeName {
      font-family: 'LinLibertine';
      font-size: 35px;
      color: #000000;
      line-height: 120%;
      margin-top: 10px; }
    .component__officeText {
      line-height: 160%;
      color: #000000;
      font-size: 20px; }
      .component__officeText b, .component__officeText strong {
        font-size: 22px;
        line-height: 200%; }
    .component__officeButton {
      margin-top: 35px;
      max-width: 316px;
      display: flex;
      align-items: center;
      padding: 23px 10px;
      justify-content: center; }
  .component__dropdown {
    padding: 25px;
    background: #F7FCFD;
    margin: 0 -25px; }
    .component__dropdownHeader {
      font-size: 22px;
      font-weight: 700;
      line-height: 120%;
      color: #000000;
      margin-bottom: 20px; }
    .component__dropdownText {
      display: none;
      color: #000000;
      font-size: 20px;
      line-height: 160%; }
      .component__dropdownText p {
        margin-top: 0; }
    .component__dropdownButton {
      text-transform: uppercase;
      font-size: 18px;
      color: #258EE6;
      font-weight: 700;
      display: inline-block;
      padding-left: 30px;
      position: relative;
      text-decoration: none;
      transition: color 0.3s; }
      .component__dropdownButton:hover {
        color: #1674c2; }
        .component__dropdownButton:hover::before {
          background: #1674c2; }
      .component__dropdownButton::before {
        content: '';
        display: block;
        width: 21px;
        height: 21px;
        background: #258EE6;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: background 0.3s; }
      .component__dropdownButton--red {
        color: #E03B56 !important; }
        .component__dropdownButton--red::before {
          background: #E03B56 !important; }
      .component__dropdownButton--yellow {
        color: #FFAD00 !important; }
        .component__dropdownButton--yellow::before {
          background: #FFAD00 !important; }
      .component__dropdownButton--purple {
        color: #AE73FF !important; }
        .component__dropdownButton--purple::before {
          background: #AE73FF !important; }
      .component__dropdownButton.active::after {
        transform: rotate(-135deg);
        top: 8px; }
      .component__dropdownButton::after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border: 3px solid rgba(0, 0, 0, 0);
        border-right-color: #FFFFFF;
        border-bottom-color: #FFFFFF;
        transform: rotate(45deg);
        position: absolute;
        left: 5px;
        top: 0;
        bottom: 3px;
        margin: auto;
        border-radius: 3px; }
  .component__logo {
    display: flex;
    margin: 0 -20px;
    flex-wrap: wrap; }
    .component__logoCol {
      padding: 0 20px;
      box-sizing: border-box;
      width: 25%;
      padding-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: center; }
      @media (max-width: 1024px) {
        .component__logoCol {
          width: 50%;
          justify-content: center;
          padding-bottom: 60px; } }
      @media (max-width: 767px) {
        .component__logoCol {
          width: 100%;
          justify-content: center; } }

html, body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media (max-width: 1024px) {
    html, body {
      overflow-x: hidden; } }
  html.overflow, body.overflow {
    overflow: hidden; }

.clear {
  clear: both; }

.container, .banner__container, .header__container, .section__container, .bannerSection__container, .footer__container, .pageBlock__container {
  max-width: 1735px;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media (max-width: 1734px) {
    .container, .banner__container, .header__container, .section__container, .bannerSection__container, .footer__container, .pageBlock__container {
      max-width: 1315px; } }
  @media (max-width: 1299px) {
    .container, .banner__container, .header__container, .section__container, .bannerSection__container, .footer__container, .pageBlock__container {
      max-width: 768px; } }

img {
  max-width: 100%;
  height: auto; }
  img.shadow {
    box-shadow: 50px 50px 50px 0 rgba(0, 0, 0, 0.18); }

.scrollTop {
  position: fixed;
  right: 10px;
  bottom: 57px;
  width: 49px;
  height: 49px;
  background: #000000;
  border-radius: 100%;
  border: 1px solid #FFFFFF;
  z-index: 10;
  transform: translateY(200px);
  transition: transform 0.3s ease-in-out; }
  .scrollTop.active {
    transform: translateY(0); }
  .scrollTop::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-left: 3px solid #FFFFFF;
    border-top: 3px solid #FFFFFF;
    transform: rotate(45deg);
    position: absolute;
    top: 5px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    border-radius: 3px;
    transition: border-color 0.3s; }
  @media (min-width: 1025px) {
    .scrollTop:hover::after {
      border-color: #FFAD00; } }
  @media (max-width: 1024px) {
    .scrollTop:active::after {
      border-color: #FFAD00; } }

.beam {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  border-top: 1px solid #FFFFFF;
  background: #000000;
  padding: 0 22px;
  box-sizing: border-box;
  height: 48px;
  align-items: center;
  transform: translateY(500%);
  transition: transform 0.3s ease-in-out; }
  .beam.show {
    transform: translateY(0); }
  .beam__row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around; }
  .beam__col {
    width: 33.33%; }
    .beam__col:nth-child(2) {
      text-align: center; }
    .beam__col:last-child {
      display: flex;
      justify-content: flex-end; }
  .beam__menu {
    width: 26px;
    height: 20px;
    position: relative; }
    .beam__menu span {
      width: 100%;
      height: 2px;
      background: #FFFFFF;
      margin-bottom: 7px;
      display: block;
      transition: transform 0.3s, opacity 0.3s, visibility 0.3s; }
      .beam__menu span:last-child {
        margin-bottom: 0; }
    .beam__menu.active span:nth-child(1) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(45deg); }
    .beam__menu.active span:nth-child(2) {
      opacity: 0;
      visibility: hidden; }
    .beam__menu.active span:nth-child(3) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(-45deg); }
  @media (max-width: 1299px) {
    .beam {
      display: flex; } }
  .beam__content {
    position: fixed;
    bottom: 48px;
    left: 0;
    right: 0;
    padding: 22px;
    background: #000000;
    border-top: 1px solid #FFFFFF;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s; }
    .beam__content.active {
      opacity: 1;
      visibility: visible; }
  .beam__text {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    font-size: 16px;
    display: flex;
    justify-content: center;
    margin-bottom: 8px; }
    .beam__text:last-child {
      margin-bottom: 0; }
    .beam__text img {
      display: inline-block;
      margin-right: 10px; }
    .beam__text a {
      color: #FFFFFF;
      text-decoration: none;
      transition: color 0.3s; }
    .beam__text--bold a {
      font-weight: 900; }

@media (max-width: 767px) {
  .coupon {
    max-width: 350px;
    width: 100%; } }
